import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "calendar",
    "modal",
    "start_time",
    "end_time",
    "timezone",
  ];

  static values = {
    eventsUrl: String,
    agendaUrl: String,
    startAt: String,
    create: Boolean,
    update: Boolean,
    reboot: Boolean
  };

  icons = {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "far fa-arrow-up",
    down: "far fa-arrow-down",
    next: "far fa-chevron-right",
    previous: "far fa-chevron-left",
    today: "far fa-crosshairs calendar-today-button",
    close: "far fa-times-circle",
  };

  connect() {
    if (this.hasCalendarTarget) this.initCalendar();
    if (this.element.classList.contains('s-upgrades')) this.initScheduledUpgradesModal();
    if (this.element.classList.contains('s-reboots')) this.initScheduledRebootsModal();
  }

  initMultiSelect(element) {
    if (!element) return;

    $(element).multiselect({
      templates: {
        button: '<button type="button" class="multiselect dropdown-toggle btn btn-primary fleet-btn outlined-btn" data-toggle="dropdown"><span class="multiselect-selected-text"></span></button>',
        filter: '<li class="multiselect-item multiselect-filter"><div class="input-group m-1"><span class="input-group-text"><i class="far fa-search"></i></span><input class="form-control multiselect-search" type="text" /></div></li>',
        filterClearBtn: '<button class="btn btn-default multiselect-clear-filter" type="button"><i class="far fa-ban"></i></button>'
      },
      disableIfEmpty: true,
      includeSelectAllOption: true,
      enableFiltering: true,
      includeFilterClearBtn: true,
      numberDisplayed: 1,
      maxHeight: 500
    });
  }

  initBootstrapToggle(element) {
    if (!element) return;

    $(element).bootstrapToggle({
      offstyle: 'secondary',
      style: 'border'
    });

    if (this.element.classList.contains('s-reboots')) {
      $(element).change((event) => {
        if (this.hasTimezoneTarget) this.timezoneTarget.disabled = !event.target.checked;
      });
    }
  }

  initScheduledRebootsModal() {
    this.initMultiSelect('.select-multiple-checkbox');
    this.initBootstrapToggle('input[data-toggle][type="checkbox"]');
    $('#scheduled_reboot_date').datetimepicker({
      format: "M/D/YYYY, h:mm a",
      sideBySide: true,
      keepOpen: true,
      buttons: { showToday: true, showClose: true },
      todayHighlight: true,
      icons: this.icons
    });
  }

  initScheduledUpgradesModal() {
    const dataElement = document.querySelector('[data-scheduled-upgrades-start-at-value]');

    this.initMultiSelect('.select-multiple-checkbox');
    this.initBootstrapToggle('input[data-toggle][type="checkbox"]');

    $('#new_scheduled_upgrade_start_at').datetimepicker({
      date: dataElement.dataset.scheduledUpgradesStartAtValue,
      format: "M/D/YYYY, h:mm a",
      sideBySide: true,
      keepOpen: true,
      buttons: { showToday: true, showClose: true },
      todayHighlight: true,
      icons: this.icons
    });

    $('#scheduled_upgrade_schedule_mode').change(function(e) {
      if ($(this).val() == 'staggered') {
        $('#stagger_map').collapse('show');
      } else {
        $('#stagger_map').collapse('hide');
      }

      var desiredStagger = $(this).find(':selected').data('stagger');

      if (desiredStagger) {
        var parts = desiredStagger.split('-');

        var firstRow = $('.stagger_map').find('.stagger_map_row:first-of-type');
        // remove all but the first
        $('.stagger_map').find('.stagger_map_row:not(:first-of-type)').remove()

        parts.forEach(function(percentage, index) {
          var thisRow = $('.stagger_map').find('.stagger_map_row')[index];
          // console.log(index);
          if (thisRow) {
            // console.log(thisRow);
          } else {
            var addEl = $('.stagger_map').find('.add-day');
            addStaggeredUpgradeDay(addEl);
            var thisRow = $('.stagger_map').find('.stagger_map_row')[index];
          }
          $(thisRow).find('input[name*="days"]').val(index + 1);
          $(thisRow).find('input[name*="percents"]').val(percentage);
        })
      }

    })
  }

  fetchUpcomingEvents({ startDate, endDate }) {
    const agendaUrl = `${this.agendaUrlValue}?start=${startDate}&end=${endDate}&_=${Math.round(+new Date()/1000)}`;
    fetch(agendaUrl)
      .then(response => response.text())
      .then(message => {
        Turbo.renderStreamMessage(message);
      });
  }

  renderTurboStreamMessage(message) {
    Turbo.renderStreamMessage(
      `<turbo-stream action="replace" target="scheduled-upgrades-frame"><template>${message}</template></turbo-stream>`
    );
  }

  initCalendar() {
    const _this = this;
    $(this.calendarTarget).fullCalendar({
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      height: 'auto',
      selectable: true,
      buttonText: {
        today:    'Today',
        month:    'Month',
        week:     'Week',
        day:      'Day',
        list:     'List'
      },
      select: (startDate, endDate) => {
        // check permissions
        if (!_this.createValue) return;

        const selectedDate = moment(startDate).format('YYYY/MM/DD');
        const todayDate = moment().format('YYYY/MM/DD');
        // make days in the past not clickable
        if (selectedDate >= todayDate) {
          // launch the modal with the selected date range pre-filled
          // NOTE: this event fires for both a single dayClick and a range
          const url = `scheduled_upgrade_new?layout=false&start_at=${startDate.format()}&end_at=${endDate.format()}`;
          fetch(url)
            .then(response => response.text())
            .then(message => {
              // Wrap meesage to the turbo-stream tag
              _this.renderTurboStreamMessage(message);
            });
        }
      },
      eventClick: (eventObj, e) => {
        e.preventDefault();
        const { url, kind } = eventObj;
        if(!url) return;

        // check permissions
        if (
          (kind === 'upgrade' && !_this.createValue) ||
          (kind === 'reboot' && !_this.rebootValue)
        ) return;

        fetch(url)
          .then(response => response.text())
          .then(message => {
            // Wrap meesage to the turbo-stream tag
            _this.renderTurboStreamMessage(message);
          });
      },
      eventRender: (eventObj, element) => {
        element.tooltip({
          title: eventObj.title
        });
      },
      eventAfterAllRender: ({ start, end }) => {
        // reinit tooltip
        $('[data-toggle="tooltip"').tooltip();

        // Fetch upcoming events
        const startDate = start.format();
        const endDate = end.format();
        _this.fetchUpcomingEvents({ startDate, endDate });
      },
      editable: false,
      events: this.eventsUrlValue
    });
  }
}
