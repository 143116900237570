import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['table', 'filter', 'sorting', 'form', 'page', 'rpp'];

  static values = { builderUrl: String };

  connect() {}

  sorting() {
    const params = {};
    const sortingValue = this.sortingTarget.value;
    if (sortingValue !== '') {
      const [key, value] = sortingValue.split('|');
      params.sorting = { [key]: value };
    }

    return params;
  }

  filtering() {
    const params = {};
    if (this.filterTarget.value !== '') {
      params.filter = this.filterTarget.value
    }

    return params;
  }

  paginationParams() {
    const params = {};
    if (this.pageTarget.value !== '') params.page = this.pageTarget.value;
    if (this.rppTarget.value !== '') params.rpp = this.rppTarget.value;

    return params;
  }

  getParams() {
    return {
      ...this.sorting(),
      ...this.filtering(),
      ...this.paginationParams()
    };
  }

  prepareParams() {
    return $.param(this.getParams());
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.tableTarget.src = `${document.location.pathname}?${this.prepareParams()}`;
    }, 500);
  }

  uploadFile(event) {
    const ids = ['software_package_remote_url', 'software_package_username', 'software_package_password'];
    const { target, target: { form } } = event;
    const collection = Array.from(form.elements);
    if (target.files.length > 0) {
      target.nextElementSibling.innerHTML = target.files[0].name;
      const inputName = collection.find(el => el.id === 'software_package_name');
      if (inputName && inputName.value === '') inputName.setAttribute('value', target.files[0].name);
      ids.forEach(id => {
        const element = collection.find(el => el.id === id);
        if (element) element.disabled = true;
      });
    } else {
      target.nextElementSibling.innerHTML = '';
      ids.forEach(id => {
        const element = collection.find(el => el.id === id);
        element.disabled = false;
      });
    }
  }

  isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  submitForm(event) {
    let isValid = this.validateForm(this.formTarget);
    this.formTarget.classList.add('was-validated');

    if (!isValid) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  validateForm() {
    let isValid = true;
    let requiredFieldSelectors = 'input:required';

    const nameField = this.formTarget.querySelector('#software_package_name');
    const fileField = this.formTarget.querySelector('#software_package_file');
    const remoteUrlField = this.formTarget.querySelector('#software_package_remote_url');
    const usernamelField = this.formTarget.querySelector('#software_package_username');
    const passwordField = this.formTarget.querySelector('#software_package_password');
    
    // Reset state after previous attempt of validation
    nameField.required = false;
    usernamelField.required = false;
    passwordField.required = false;
    
    // If a file was uploaded, then the name field must be required
    // Otherwise, if the url field is empty or matches the official repository url, then the username and password fields must be required
    if (fileField.files.length > 0) {
      nameField.required = true;
    } else if (remoteUrlField.value === '' || remoteUrlField.value.includes(this.builderUrlValue)) {
      usernamelField.required = true;
      passwordField.required = true;
    }

    // Checking the url format
    if (remoteUrlField.value !== '' && !this.isValidHttpUrl(remoteUrlField.value)) {
      remoteUrlField.setCustomValidity("Invalid format.");
    } else {
      remoteUrlField.setCustomValidity("");
    }

    // Validating form fields
    const requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);
    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();
        isValid = false;
      }
    });

    return isValid;
  }

  pagination(event) {
    const { target } = event;

    const url = new URL(target.href);
    const params = {
      ...this.getParams(),
      page: url.searchParams.get('page'),
      rpp: url.searchParams.get('rpp')
    }

    target.href = `${document.location.pathname}?${$.param(params)}`;
  }

  replaceFile(event) {
    const { target } = event;
    [
      '#software_package_file',
      '#software_package_remote_url',
      '#software_package_username',
      '#software_package_password'
    ].forEach(id => {
      const element = this.formTarget.querySelector(id);
      element.parentNode.parentNode.classList.remove('d-none');
      element.disabled = false;
    });
    const inputGroup = target.parentNode.parentNode;
    inputGroup.nextElementSibling.classList.remove('d-none');
    inputGroup.parentNode.removeChild(inputGroup);
  }
}
